import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import * as turf from "@turf/turf";
import { agreement, getAmapGeo, getAmapRegeo, getAuth, logout } from "api/layer";
import china from "assets/json/c1.json";
import country from "assets/json/country.json";
import Button from "components/button/button";
import InfoModal from "components/infoModal/infoModal";
import Input from "components/input/input";
import { LeftPanel, RightPanel, zoomLevelMax } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { MapContext } from "helpers/map";
import { LocationData } from "helpers/models";
import { guardLanguage, langCn, langEn, setLanguage } from "helpers/translations";
import i18next from "i18next";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as ContactSvg } from "../../assets/contact.svg";
import { ReactComponent as DataSourceSvg } from "../../assets/data_source.svg";
import { ReactComponent as IconHelpSvg } from "../../assets/icon_help.svg";
import { ReactComponent as LogoutSvg } from "../../assets/logout.svg";
import { ReactComponent as TermsSvg } from "../../assets/terms.svg";
import styles from "./header.module.scss";
import Location from "./location/location";
import { LayerInfoModal } from "helpers/constants";
import Modal from "components/modal/modal";
import TipModal from "./info-modal/info-modal";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

let chinaGeojson = turf.polygon(china.features[0].geometry.rings);
function Header() {
	const [context, dispatch] = useContext(AppContext);
	const { AMap, map, placeSearch } = useContext(MapContext);
	const [search, setSearch] = useState<string>("");
	const [nationSearchSelect, setNationSearchSelect] = useState<string>(context.language === "cn" ? "中国" : "China");
	const [loginAccount, setLoginAccount] = useState<string>("AI@swissre");
	const [miniName, setMiniName] = useState<string>("AI");
	const [userName, setUserName] = useState<string>("AI");
	const [selectSearchType, setSelectSearchType] = useState<string>("1");
	const [showSearch, setShowSearch] = useState<boolean>(false);
	const [showNation, setShowNation] = useState<boolean>(false);
	const [infoModalOpened, setInfoModalOpened] = useState<boolean>(false);
	const [termsOfUseModalOpened, setTermsOfUseModalOpened] = useState<boolean>(false);
	const [termsOfUseChecked, setTermsOfUseChecked] = useState<boolean>(false);
	const [infoModalType, setInfoModalType] = useState<string>("");
	const [searchData, setSearchData] = useState<any>({ cn: [], en: [] });
	const [t] = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [avatarEl, setAvatarEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const avataOpen = Boolean(avatarEl);
	const [tipModel, setTipModel] = useState<boolean>(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAvatarEl(event.currentTarget);
	};

	const handleAvatarClose = () => {
		setAvatarEl(null);
	};

	const filterCountry = country.filter((item) => item.label.toLowerCase().includes(nationSearchSelect.toLowerCase()));

	const changeLanguage = (lang: string): void => {
		setLanguage(lang);
		i18next.changeLanguage(guardLanguage(lang));
		dispatch({ type: AppAction.setLanguage, payload: lang });
	};

	const getLangClass = (lang: string): string => (context.language === lang ? styles.langSelected : styles.lang);

	const selectSwissRe = (poiResultItem: any, isZoom: boolean = true): void => {
		const singleLocation = {
			name: poiResultItem[context.language].formatted_address,
			address: poiResultItem[context.language].formatted_address,
			lon: poiResultItem[context.language].location.split(",")[0],
			lat: poiResultItem[context.language].location.split(",")[1],
			oversea: poiResultItem[context.language].oversea,
			spatial: poiResultItem[context.language].spatial,
		} as LocationData;
		isZoom && map.setZoom(zoomLevelMax);
		dispatch({ type: AppAction.setSingleLocation, payload: singleLocation });
		dispatch({ type: AppAction.setSearchLocationInfo, payload: poiResultItem });
		dispatch({ type: AppAction.setSearchMapCenter, payload: [singleLocation.lon, singleLocation.lat] });
		dispatch({ type: AppAction.setAnalysisData, payload: null });
		dispatch({ type: AppAction.setBatchId, payload: undefined });
		dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
		dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: undefined })
		dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Single });
		// dispatch({ type: AppAction.setRightPanel, payload: (context.leftPanel === LeftPanel.Empty || context.leftPanel === LeftPanel.Layers) ? RightPanel.Single : RightPanel.Multiple });
	};

	const getSearchData = async (event: any) => {
		let nationObj = country.filter((item) => item.label.toLowerCase().includes(nationSearchSelect.toLowerCase()))[0];
		let res: any = await getAmapGeo({
			address: event.target.value,
			country: nationObj.value,
		});
		if (res.data && res.data[0] && res.data[0].status === "1") {
			setSearchData({ cn: res.data[0].geocodes, en: res.data[1].geocodes, country: nationObj.value });
		} else {
			setSearchData({ cn: [], en: [], country: nationObj.value });
		}
	};

	const searArea = debounce((event: any) => {
		if (!event.target.value) {
			setSearchData({ cn: [], en: [] });
			setShowSearch(false);
		} else {
			setShowSearch(true);
			// 调用 getSearchData 函数，并传递事件对象
			getSearchData(event);
		}
	}, 500);

	const handleInfoModalType = (type: string): void => {
		handleClose();
		setInfoModalOpened(true);
		setInfoModalType(type);
	};

	const handleChange = (event: any) => {
		setSelectSearchType(event.target.value);
		setSearch("");
		setSearchData({ cn: [], en: [] });
	};

	const handleTipModal = () => {
		dispatch({
			type: AppAction.setTipModal,
			payload: {
				name: (
					<div style={{ display: "flex", alignItems: "center" }}>
						<span
							className="material-icons"
							style={{ marginRight: "8px" }}
						>
							{"info"}
						</span>
						{t("tip.title")}
					</div>
				),
				description: t("tip.message"),
			},
		});
	};

	const checkInput = (location: string): boolean => {
		const locationArr = location.split(",");
		if (locationArr.length !== 2) {
			return false;
		}
		const lon = locationArr[0];
		const lat = locationArr[1];
		if (isNaN(Number(lon)) || isNaN(Number(lat))) {
			return false;
		}
		const longitudeNumber = Number(lon);
		const latitudeNumber = Number(lat);
		if (longitudeNumber >= -180 && longitudeNumber <= 180 && latitudeNumber >= -90 && latitudeNumber <= 90) {
			return true;
		}
		return false;
	};

	const handleDownEnter = async (event: any) => {
		const location = event.target.value.trim().split(" ").join("");
		const v = checkInput(location);

		event.stopPropagation();
		if (!v) {
			handleTipModal();
			event.preventDefault();
			event.stopPropagation();
			return;
		}
		let params = {
			spatial: "",
			location,
			oversea: false,
		};
		if (selectSearchType === "2") {
			params.spatial = "gcj";
		} else if (selectSearchType === "3") {
			params.spatial = "wgs";
			params.oversea = true;
		} else if (selectSearchType === "4") {
			params.spatial = "wgs";
		}
		let res: any = await getAmapRegeo(params);
		if (res.data.length) {
			// const adcode = res.data[0].regeocode.addressComponent.adcode;
			// if (!adcode || adcode.length < 6) {
			// 	handleTipModal();
			// 	return;
			// }
			let location = res.data[0].regeocode.addressComponent.streetNumber.location || params.location;
			selectSwissRe({
				cn: {
					...params,
					location: res.data[0].location,
					oversea: res.data[0].oversea,
					spatial: res.data[0].spatial,
					...res.data[0].regeocode.addressComponent,
					// formatted_address: res.data[0].regeocode.formatted_address.length > 0 ? res.data[0].regeocode.formatted_address : t("single.noData"),
					formatted_address: res.data[0].regeocode.formatted_address,
				},
				en: {
					...params,
					location: res.data[1].location,
					oversea: res.data[1].oversea,
					spatial: res.data[1].spatial,
					...res.data[1].regeocode.addressComponent,
					// formatted_address: res.data[1].regeocode.formatted_address.length > 0 ? res.data[1].regeocode.formatted_address : t("single.noData"),
					formatted_address: res.data[1].regeocode.formatted_address,
				},
				...params,
				location: location,
			});
		}
	};

	//防抖 N秒内重复触发的话只触发最后一次
	function debounce<T extends (...args: any[]) => void>(fn: T, delay: number, immediate = false, executeImmediately = false): (...args: Parameters<T>) => void {
		let timer: NodeJS.Timeout | null = null;
		let isInvoke = false;

		const _debounce = function (this: unknown, ...args: Parameters<T>): void {
			const executeNow = immediate && !isInvoke && executeImmediately;

			if (timer) clearTimeout(timer);

			if (executeNow) {
				fn.apply(this, args);
				isInvoke = true;
			} else {
				timer = setTimeout(() => {
					fn.apply(this, args);
					isInvoke = false;
				}, delay);
			}
		};

		return _debounce;
	}

	const getAuthUser = () => {
		getAuth().then((res: any) => {
			const loginAccount = res.data.loginAccount;
			const miniName = res.data.details.miniName;
			const userName = res.data.userName;
			const loginFirstTime = res.data.details.loginFirstTime;
			setLoginAccount(loginAccount);
			setMiniName(miniName);
			setUserName(userName);
			if (loginFirstTime === 0) {
				setTermsOfUseModalOpened(true);
			}
		});
	};
	const handleTermsOfUseConfirm = () => {
		agreement().then((res: any) => {
			setTermsOfUseModalOpened(false);
		});
	};

	const logoutAccount = () => {
		// window.location.href=`https://non-prod.identity.swissre.cn/enduser/sp/logout/idaas-cn-pe335gkeq01plugin_oauth22?force=true&redirect_url=${process.env.REACT_APP_BASE_API}`
		logout().then((res) => {
			sessionStorage.clear();
			window.location.href = res.data.redirectUrl;
		});
	};

	const mapClickFn = async (e: any) => {
		let point = [e.lnglat.getLng(), e.lnglat.getLat()];
		let params = {
			spatial: "gcj",
			location: point.join(","),
			oversea: false,
		};
		if (!turf.booleanPointInPolygon(point, chinaGeojson)) {
			params.spatial = "wgs";
			params.oversea = true;
		}
		let res = await getAmapRegeo(params);
		if (res.data.length) {
			let location = res.data[0].regeocode.addressComponent.streetNumber.location || params.location;
			selectSwissRe(
				{
					cn: {
						...params,
						location: res.data[0].location,
						oversea: res.data[0].oversea,
						spatial: res.data[0].spatial,
						...res.data[0].regeocode.addressComponent,
						// formatted_address: res.data[0].regeocode.formatted_address.length > 0 ? res.data[0].regeocode.formatted_address : t("single.noData"),
						formatted_address: res.data[0].regeocode.formatted_address,
					},
					en: {
						...params,
						location: res.data[1].location,
						oversea: res.data[1].oversea,
						spatial: res.data[1].spatial,
						...res.data[1].regeocode.addressComponent,
						// formatted_address: res.data[1].regeocode.formatted_address.length > 0 ? res.data[1].regeocode.formatted_address : t("single.noData"),
						formatted_address: res.data[1].regeocode.formatted_address,
					},
					...params,
					location: location,
				},
				false
			);
		}
	};

	useEffect(() => {
		if (!context.mapClickState) {
			map?.on("click", mapClickFn);
		} else {
			map.off("click", mapClickFn);
		}

		return () => {
			if (map) {
				map.off("click", mapClickFn);
			}
		};
	}, [map, context.mapClickState, context.leftPanel]);

	useEffect(() => {
		getAuthUser();
	}, []);

	return (
		<div className={styles.header}>
			<div className={styles.logo}>
				<Link
					to="/"
					className="pr-6"
					onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)}
				>
					<img
						// title="SwissRe_Logo"
						alt="SwissRe_Logo"
						src="/SwissRe_Logo.svg"
					/>
				</Link>
				<div className="text-[19px]">
					CatNet<sup>®</sup>
				</div>
			</div>
			<div className={styles.search}>
				{selectSearchType === "1" ? (
					<div className={styles.nationSearch}>
						<div className={`material-icons ${styles.nationSearchIcon}`}>search</div>
						<input
							value={nationSearchSelect}
							className={styles.nationSearchSelect}
							placeholder={t("header.country")}
							onFocus={() => setTimeout(() => setShowNation(true), 100)}
							onBlur={() => setTimeout(() => setShowNation(false), 200)}
							onChange={(event: any) => setNationSearchSelect(event.target.value)}
						/>
						<span></span>
						<input
							value={search}
							className={styles.nationSearchInput}
							placeholder={t("header.placeholderAddress")}
							onFocus={() => setTimeout(() => searchData[context.language].length > 0 && setShowSearch(true), 500)}
							onBlur={() => setTimeout(() => setShowSearch(false), 500)}
							onChange={(event) => {
								setSearch(event.target.value);
								searArea(event);
							}}
						/>
						{search && (
							<div
								className={`material-icons ${styles.close}`}
								onClick={() => setSearch("")}
							>
								close
							</div>
						)}
					</div>
				) : (
					<Input
						value={search}
						icon="search"
						isClose={true}
						id="tipinput"
						placeholder={t("header.placeholderCoordinates")}
						onChange={(event) => setSearch(event.target.value)}
						onDownEnter={handleDownEnter}
						onClose={() => setSearch("")}
					/>
				)}
				{<TipModal />}
				{showNation && nationSearchSelect && (
					<div className={styles.nationLocations}>
						{filterCountry.map((item, index) => (
							<div
								className={styles.nationLocation}
								key={index}
								onClick={() => setNationSearchSelect(item.label)}
							>
								<Location text={item.label} />
							</div>
						))}
					</div>
				)}

				{showSearch && (
					<div className={styles.locations}>
						{searchData[context.language].length === 0 ? (
							<div
								className="loading loading-spinner loading-lg text-center w-full h-[40px]"
								style={{ maskSize: "auto" }}
							></div>
						) : (
							searchData[context.language].map((item: any, index: number) => (
								<div
									className={styles.location}
									onClick={() =>
										selectSwissRe({
											cn: searchData.cn[index],
											en: searchData.en[index],
											country: searchData.country,
										})
									}
									key={index}
								>
									<Location
										text={item && item.formatted_address}
										showIcon={true}
									/>
								</div>
							))
						)}
					</div>
				)}
			</div>
			<div className={`flex items-center ${styles.right}`}>
				<Select
					// title="change geo scheme"
					className={styles.geo}
					value={selectSearchType}
					onChange={handleChange}
					color="success"
				>
					{/* <option value="1" >
            {t("header.geo.addressInside")}
          </option>
          <option value="2">{t("header.geo.addressOutside")}</option> */}
					<MenuItem value="1">{t("header.geo.addressInformation")}</MenuItem>
					<MenuItem value="2">{t("header.geo.gcj02")}</MenuItem>
					<MenuItem value="3">{t("header.geo.wsg84Outside")}</MenuItem>
					<MenuItem value="4">{t("header.geo.wsg84Inside")}</MenuItem>
				</Select>
				<div
					className="dropdown dropdown-end tooltip tooltip-bottom"
					data-tip={t("header.help.title")}
				>
					<IconButton
						id="basic-button"
						aria-controls={open ? "basic-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						onClick={handleClick}
						className={styles.customIconButton}
					>
						<IconHelpSvg className={`${open ? "fill-[#007934]" : "fill-[#0009]"} m-1 focus:outline-none`} />
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
						PaperProps={{
							elevation: 10,
							sx: {
								"& .MuiList-root": {
									py: 0,
								},
							},
						}}
					>
						<ul className="card compact dropdown-content z-[1] p-0 shadow bg-base-100  w-[200px] rounded-[4px] overflow-hidden">
							<li>
								<div className="flex justify-between items-center rounded-none text-[14px] px-4 py-0 h-12">
									<span className="text-[#0009] font-medium">{t("header.help.title")}</span>
									<span className="text-[#00000061] font-normal">{t("header.help.ver")} 1.2</span>
								</div>
							</li>
							<div className="border-t-[1px] border-solid border-[#0000001f]"></div>
							<li
								className={styles.dropdownContent}
								onClick={() => handleInfoModalType("dataSources")}
							>
								<div className="flex items-center rounded-none text-[14px] px-4 py-0 h-12 font-normal">
									<DataSourceSvg className="mr-4 w-6 h-6 fill-[#0000008a]" />
									<span className="text-[#000000de]">{t("header.help.dataSources")}</span>
								</div>
							</li>
							<div className="border-t-[1px] border-solid border-[#0000001f]"></div>
							<li
								className={styles.dropdownContent}
								onClick={() => handleInfoModalType("termsOfUse")}
							>
								<div className="flex items-center rounded-none text-[14px] px-4 py-0 h-12 font-normal">
									<TermsSvg className="mr-4 w-6 h-6 fill-[#0000008a]" />
									<span className="text-[#000000de]">{t("header.help.termsOfUse")}</span>
								</div>
							</li>
							<div className="border-t-[1px] border-solid border-[#0000001f]"></div>
							<li
								className={styles.dropdownContent}
								onClick={() => handleInfoModalType("contact")}
							>
								<div className="flex items-center rounded-none text-[14px] px-4 py-0 h-12 font-normal">
									<ContactSvg className="mr-4 w-6 h-6 fill-[#0000008a]" />
									<span className="text-[#000000de]">{t("header.help.contact")}</span>
								</div>
							</li>
						</ul>
					</Menu>

					<InfoModal
						header={infoModalType && t(`header.help.infoModal.${infoModalType}.header`)}
						opened={infoModalOpened}
						onClose={() => setInfoModalOpened(false)}
					>
						{infoModalType === "termsOfUse" ? (
							<embed
								src={`${process.env.PUBLIC_URL}/${context.language === "en" ? "tou_en.pdf" : "tou_cn.pdf"}#toolbar=0`}
								title="termsOfUse"
								className={styles.termsOfUse}
							></embed>
						) : (
							<div
								className={styles.modal}
								dangerouslySetInnerHTML={{ __html: infoModalType && t(`header.help.infoModal.${infoModalType}.content`) }}
							></div>
						)}
						<div className={styles.buttons}>
							<Button onClick={() => setInfoModalOpened(false)}>{infoModalType && t(`header.help.infoModal.${infoModalType}.cancel`)}</Button>
						</div>
					</InfoModal>
				</div>

				<div className="dropdown dropdown-end">
					<IconButton
						id="avatar-button"
						aria-controls={avataOpen ? "avatar-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={avataOpen ? "true" : undefined}
						onClick={handleAvatarClick}
						role="button"
						className="avatar placeholder ml-[10px]"
					>
						<div className="bg-[#d0d9d8] text-neutral-content rounded-full w-8 h-8">
							<span className="text-[#333] text-[15px] font-medium">{miniName}</span>
						</div>
					</IconButton>
					<Menu
						id="avatar-menu"
						anchorEl={avatarEl}
						open={avataOpen}
						onClose={handleAvatarClose}
						MenuListProps={{
							"aria-labelledby": "avatar-button",
						}}
						PaperProps={{
							sx: {
								"& .MuiList-root": {
									py: 0,
								},
							},
						}}
					>
						<div className="card compact dropdown-content z-[1] p-0 shadow bg-base-100 mt-[10px] w-[310px] rounded-[4px] overflow-hidden">
							<div className="flex items-center text-[14px] text-[#0009] px-4 h-12 rounded-none">{t("header.account")}</div>
							<div className="border-t-[1px] border-solid border-[#0000001f]"></div>
							<div className="p-6 text-center">
								<div className="text-[22px] mb-[5px]">{userName}</div>
								<div className="text-[14px] text-[#0009] mb-6">{loginAccount}</div>
								{/* <div className="flex items-center bg-[#627d77] h-[18px] w-fit rounded-[9px] py-px px-2 m-auto">
                <span className="text-white text-[12px]">Internal</span>
              </div> */}
							</div>
							<div className="border-t-[1px] border-solid border-[#0000001f]"></div>
							<div
								className={`${styles.dropdownContent} flex items-center text-[14px] text-[#0009] px-4 h-12`}
								onClick={logoutAccount}
							>
								<LogoutSvg className="mr-4 fill-[#0000008a] w-6"></LogoutSvg>
								<span className="font-normal">{t("header.signOut")}</span>
							</div>
						</div>
					</Menu>
				</div>

				<div className="flex">
					<div
						className={getLangClass(langEn)}
						onClick={() => changeLanguage(langEn)}
					>
						{t("header.lang.en")}
					</div>
					<div
						className={getLangClass(langCn)}
						onClick={() => changeLanguage(langCn)}
					>
						{t("header.lang.cn")}
					</div>
				</div>
			</div>

			{termsOfUseModalOpened && (
				<InfoModal
					header={t(`termsOfUse.title`)}
					opened={termsOfUseModalOpened}
					onClose={() => setTermsOfUseModalOpened(false)}
				>
					<embed
						src={`${process.env.PUBLIC_URL}/${context.language === "en" ? "tou_en.pdf" : "tou_cn.pdf"}#toolbar=0`}
						title="termsOfUse"
						className={styles.termsOfUse}
					></embed>
					<FormGroup style={{ width: "max-content" }}>
						<FormControlLabel
							control={
								<Checkbox
									color="success"
									checked={termsOfUseChecked}
									onChange={(e) => setTermsOfUseChecked(e.target.checked)}
								/>
							}
							label={t(`termsOfUse.agreement`)}
						/>
					</FormGroup>
					<div className={styles.modalBtnBody}>
						<div style={{ marginRight: "10px" }}>
							<Button
								type="outlined"
								onClick={logoutAccount}
							>
								{t(`termsOfUse.cancel`)}
							</Button>
						</div>
						<div>
							<Button
								onClick={() => handleTermsOfUseConfirm()}
								disabled={!termsOfUseChecked}
							>
								{t(`termsOfUse.confirm`)}
							</Button>
						</div>
					</div>
				</InfoModal>
			)}
		</div>
	);
}

export default Header;
