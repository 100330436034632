import Agendas from "containers/agendas/agendas";
import AnalyseModal from "containers/analyse-modal/analyse-modal";
import Layers from "containers/layers/layers";
import LocationSet from "containers/location-set/location-set";
import Locations from "containers/locations/locations";
import Map from "containers/map/amap";
// import Map from "containers/map/amap.js";
// import Map from "containers/map/map";
import MultiLocations from "containers/multi-locations/multi-locations";
import SingleLocation from "containers/single-location/single-location";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppContext, AppAction, getMultipleLocations } from "helpers/context";
import { MapSize, AgendasSize } from "helpers/models";
import { useContext, useEffect, useState } from "react";
import { getLayer, getCrsLayer } from "api/layer";
import styles from "./home.module.scss";
import ImportModal from "containers//multi-import-modal/import-modal/import-modal";
import ImportMenu from "containers//multi-import-modal/import-menu/import-menu";
import CreateModal from "containers//multi-import-modal/create-modal/create-modal";
import ErrorModal from "containers//multi-import-modal/error-modal/error-modal";
import AddLocation from "containers/location-set/add/add-location";
import EditLocationSet from "containers/locations/edit/edit-location-set";
import EditModal from "containers/multi-import-modal/edit-modal/edit-modal";
import SnackBar from "components/snackBar/snackBar"
import LocationAnalysisDetails from "components/results/location-analysis-details/location-analysis-details";
import DeleteLocationSetModal from "containers/locations/delete/delete-location-set";
import DeleteLocationModal from "containers/location-set/delete/delete-location";
import AddressEncoding from "containers/multi-import-modal/address-encoding/address-encoding";

function Home() {
	const [context, dispatch] = useContext(AppContext);


	const groupByCategory = (arr: any) => {
		let newArr: any[] = [];
		const arrF = arr.filter((e: any) => e.id !== "GEO_DistToCoast_Global_SwissRe");
		const arrG = arrF.map((e: any) => {
			if (e.id === "DR_Subsidence_France_SwissRe" || e.id === "WF_DistToBush_AUS_SwissRe") {
				return {
					...e,
					group: "Additional Local Maps",
				};
			}
			return e;
		});
		arrG.forEach((item: any, i: number) => {
			let index = -1;
			let isExists = newArr.some((newItem, j) => {
				// 这里是判断字段 根据(时间日期) month
				if (item.group === newItem.name) {
					index = j;
					return true;
				}
				return false;
			});
			if (!isExists) {
				newArr.push({
					name: item.group,
					children: [{ ...item, checked: false }],
				});
			} else {
				newArr[index].children.push({ ...item, checked: false });
			}
		});
		return newArr;
	};

	const getLayerList = () => {
		getLayer().then((res: any) => {
			const layerList = groupByCategory(res.data);
			dispatch({ type: AppAction.setLayerList, payload: layerList });
		});
	};

	const getCrsRiskTypeList = () => {
		getCrsLayer().then((res: any) => {
			dispatch({ type: AppAction.setCrsLayerList, payload: res.data });
		});
	};

	const getMultipleLocationsList = () => {
		getMultipleLocations(dispatch);
	};

	useEffect(() => {
		getLayerList();
		getCrsRiskTypeList();
		getMultipleLocationsList();
	}, []);

	const mapSize = (): MapSize => {
		if (context.arrowLeft && context.arrowRight) {
			return MapSize.L;
		}

		if (context.leftPanel === LeftPanel.Locations && context.rightPanel !== RightPanel.Empty && context.locationsSize) {
			if (context.arrowLeft) {
				return MapSize.M;
			}
			if (context.arrowRight) {
				return MapSize.S;
			}
			return MapSize.XS;
		}

		if (context.leftPanel !== LeftPanel.Empty && context.rightPanel !== RightPanel.Empty) {
			if (context.arrowLeft || context.arrowRight) {
				return MapSize.M;
			}
			return MapSize.S;
		}

		if (context.leftPanel !== LeftPanel.Empty || context.rightPanel !== RightPanel.Empty) {
			if (context.arrowLeft || context.arrowRight) {
				return MapSize.L;
			}
			return MapSize.M;
		}

		return MapSize.L;
	};

	const agendasSize = () => {
		if (context.arrowLeft || context.leftPanel === LeftPanel.Empty) {
			return AgendasSize.S;
		} else if (context.leftPanel === LeftPanel.Locations && context.locationsSize) {
			return AgendasSize.L;
		} else {
			return AgendasSize.M;
		}
	};

	return (
		<div className="flex items-center justify-between relative">
			<div
				className="z-[2]"
				style={{ display: context.arrowLeft ? "none" : "", backgroundColor: "white" }}
			>
				{context.leftPanel === LeftPanel.Layers && <Layers />}
				{context.leftPanel === LeftPanel.Locations && (context.multipleLocationsEdit ? <LocationSet /> : <Locations />)}
				{(context.leftPanel === LeftPanel.AddLocation || context.leftPanel === LeftPanel.EditLocation) && <AddLocation />}
				{context.leftPanel === LeftPanel.EditLocationSet && <EditLocationSet />}
			</div>
			<Agendas size={agendasSize()} />
			<ImportMenu />
			<ImportModal />
			{context.createModal && <CreateModal />}
			<EditModal />
			<ErrorModal />
			<LocationAnalysisDetails />
			<DeleteLocationSetModal />
			<DeleteLocationModal />
			<AddressEncoding />
			<div className="ml-[1px] relative">
				{context.leftPanel !== LeftPanel.Empty && (
					<div
						className={styles.arrowLeftIcon}
						onClick={() => {
							dispatch({ type: AppAction.setArrowLeft, payload: !context.arrowLeft });
						}}
					>
						<span className="material-icons">{context.arrowLeft ? "arrow_right" : "arrow_left"}</span>
					</div>
				)}
				<Map size={mapSize()} />
				{context.rightPanel !== RightPanel.Empty && (
					<div
						className={styles.arrowRightIcon}
						onClick={() => {
							dispatch({ type: AppAction.setArrowRight, payload: !context.arrowRight });
						}}
					>
						<span className="material-icons">{context.arrowRight ? "arrow_left" : "arrow_right"}</span>
					</div>
				)}
			</div>
			<div
				className="z-[2]"
				style={{ display: context.arrowRight ? "none" : "", backgroundColor: "white" }}
			>
				{context.rightPanel === RightPanel.Single && <SingleLocation />}
				{context.rightPanel === RightPanel.Multiple && <MultiLocations />}
			</div>
			<AnalyseModal />
			<SnackBar />
		</div >
	);
}

export default Home;
