import { Worksheet } from "exceljs";

export const tx = (col: string, value: any, worksheet: Worksheet, rowNum: any, t: any) => {
	console.log(col, value, worksheet, rowNum);

	if (!col) {
		return;
	}

	let row = 2;
	if (!rowNum[col]) {
		rowNum[col] = 2;
	} else {
		row = rowNum[col] + 1;
		rowNum[col] = row;
	}

	console.log(row);

	const cellInt = worksheet.getCell(`${col}${row}`);

	if (value === "0" || value === 0) {
		cellInt.value = 1;
		return;
	}

	if (typeof value === "number" || t === null) {
		cellInt.value = value;
		return;
	}

	if (!isNaN(value)) {
		const valueInt = Math.round(value * 100) / 100;
		cellInt.value = valueInt;
		return;
	}

	const valueFy = t("legend." + value);
	if (!valueFy.startsWith("legend.")) {
		cellInt.value = valueFy;
		return;
	}

	cellInt.value = value;
};
