import { GET, POST, PUT, DELETE, PATCH } from "request/service";

export const getLayer = async () => {
	// return await GET(`/risklayer-api/catnet/Layers`);
	return await GET(`/risklayer-api/catnet-cn/Layers`);
};

export const getCrsLayer = async () => {
	return await GET(`/risklayer-api/crs/riskTypeList`);
};

export const getLegend = async (layerID: string) => {
	return await GET(`/risklayer-api/catnet/layers/${layerID}/legend`);
};

export const getLegends = async (params: any) => {
	return await GET(`/risklayer-api/catnet/legends`, { params });
};

export const getAnalysis = async (params: any) => {
	return await GET(`/risklayer-api/catnet/layersAnalysis/analysis`, { params });
};

export const getStartAnalysis = async (params: any) => {
	return await POST(`/singlesite-api/riskAnalysis/startAnalysis`, params);
};

export const getCrsAnalysisResult = async (batchId: string) => {
	return await GET(`/risklayer-api/crs/lookup/${batchId}`);
};

export const getAmapGeo = async (params: any) => {
	return await GET(`/risklayer-api/amap/geo`, { params });
};

export const getAmapRegeo = async (params: any) => {
	return await GET(`/risklayer-api/amap/regeo`, { params });
};

export const downloadReport = async (params: any) => {
	return await POST(`/singlesite-api/report/exportReportPdf`, params, { responseType: "blob" });
};

export const downloadHazardPdf = async (params: any) => {
	return await POST(`/data-report/hazard/dl`, params, { responseType: "blob" });
};

export const getAuth = async () => {
	return await POST(`/auth/user/authorize`);
};

export const logout = async () => {
	return await POST(`/auth/user/logout`);
};

export const agreement = async () => {
	return await POST(`/auth/user/sign`);
};

export const downloadClimatePdf = async (params: any) => {
	return await POST(`../data-report/climate/dl`, params, { responseType: "blob" });
};

export const getLocationsets = async () => {
	return await GET(`/locationset-api/manage/locationsets`);
};

export const addLocationsets = async (params: any) => {
	return await POST(`/locationset-api/manage/locationsets`, params);
};

export const deleteLocationsets = async (id: any) => {
	return await DELETE(`/locationset-api/manage/locationsets/${id}`);
};

export const patchLocationsets = async (id: any, params: any) => {
	return await PATCH(`/locationset-api/manage/locationsets/${id}`, params);
};

export const getLocationsetsItems = async (id: any) => {
	return await GET(`/locationset-api/manage/locationsets/${id}`);
};

export const addLocationSetItems = async (id: any, params: any) => {
	return await POST(`/locationset-api/manage/locationsets/${id}/items`, params);
};

export const deleteLocationsetsItems = async (locationSetId: any, locationId: any) => {
	return await DELETE(`/locationset-api/manage/locationsets/${locationSetId}/items/${locationId}`);
};

export const patchLocationsetsItems = async (locationSetId: any, locationId: any, params: any) => {
	return await PATCH(`/locationset-api/manage/locationsets/${locationSetId}/items/${locationId}`, params);
};

export const startMultipleAnalysis = async (params: any) => {
	return await POST(`/locationset-api/asyncBatchJob/createMultipleChunks`, params);
};

export const batchGeo = async (params: any) => {
	return await POST(`/locationset-api/amap/batchGeo`, params);
};

export const batchGeoStatus = async (params: any) => {
	return await GET(`/locationset-api/amap/batchGeoStatus`, { params });
};

export const batchGeoResult = async (params: any) => {
	return await GET(`/locationset-api/amap/batchGeoResult`, { params });
};

export const getMultipleAnalysisStatus = async (jobId: string) => {
	return await GET(`/locationset-api/asyncBatchJob/checkStatus/${jobId}`);
};

export const getMultipleAnalysisResult = async (jobId: string, tabIndex: string) => {
	return await GET(`/locationset-api/asyncBatchJob/getApiResponseResultByTabId/${jobId}/TabGroup/${tabIndex}`);
};
