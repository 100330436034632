import { LocationData } from "./models";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const getCounts = (locations?: LocationData[]): string => {
  if (!locations || locations.length === 0) return "0/0";
  return `${locations?.filter((location: LocationData) => location.checked).length}/${locations?.length
    }`;
}

export const getCheckedCounts = (locations?: LocationData[]): string => {
  if (!locations || locations.length === 0) return "0";
  return `${locations?.filter((location: LocationData) => location.checked).length}`;
}

export const formatCurrency = (num: number): string =>
  formatter.format(num).replace("$", "");
